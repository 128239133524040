<template>
    <div class="chat-right py-2 position-relative">
        <div id="scrollGeneral" class="overflow-auto scroll-old" style="height: calc(100vh - 185px);">
            <div class="row mx-0">
                <div class="col text-center">
                    <button v-show="masMensajes" class="bg-secondary px-3 border text-white br-5 py-1 f-14 f-300" @click="getConversacion">
                        Cargar más mensajes...
                    </button>
                </div>
                <div v-for="(c,key) in conversacion" :key="key" class="w-100">
                    <div class="col-12 text-center my-3">
                        <span class="text-86 bg-f5 border f-12 rounded-pill px-2 py-1">{{ c.key | helper-fecha('DD MMMM [de] YYYY') }}</span>
                    </div>
                    <div v-for="(m,i) in c.mensajes" :key="i">
                        <div v-if="m.propio" class="col-9 ml-auto mb-2">
                            <div class="row mx-0 justify-content-end">
                                <div class="col-auto px-0 br-5 chat-bubble chat-bubble-right">
                                    <div class="row mx-0 my-1 f-13">
                                        <div class="col py-1">
                                            <div class="row mx-0 justify-content-end">
                                                <p v-if="m.tipo === 1" style="word-break: break-all;white-space: break-spaces;">
                                                    {{ m.mensaje }}
                                                </p>
                                                <img v-if="m.tipo === 2" class="w-100 cr-pointer" :src="m.mensaje" style="max-width:200px;max-height:200px;" />
                                            </div>
                                            <div class="row mx-0 justify-content-end f-11 text-86">
                                                {{ m.created_at | helper-fecha('HH:mm a') }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="chat-bubble-arrow-right" />
                                </div>
                            </div>
                        </div>
                        <div v-else class="col-9 mr-auto mb-2">
                            <div class="row mx-0">
                                <div class="col-auto px-0 br-5 chat-bubble chat-bubble-left">
                                    <div class="row mx-0 my-1 f-13">
                                        <div class="col py-1">
                                            <div class="row mx-0">
                                                <p class="w-100 text-general f-500">
                                                    {{ m.user.nombre }}
                                                </p>
                                                <p class="text-secondary mb-2">
                                                    {{ m.user.vivienda }}
                                                </p>
                                            </div>
                                            <div class="row mx-0">
                                                <p v-if="m.tipo === 1" style="word-break: break-all;white-space: break-spaces;">
                                                    {{ m.mensaje }}
                                                </p>
                                                <img v-if="m.tipo === 2" class="w-100 cr-pointer" :src="m.mensaje" style="max-width:200px;max-height:200px;" />
                                            </div>
                                            <div class="row mx-0 justify-content-end f-11 text-86">
                                                {{ m.created_at | helper-fecha('HH:mm a') }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="chat-bubble-arrow-left" />
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <div v-loading="loading" class="chat-input">
            <div class="row bg-f1 py-2">
                <div class="col-12">
                    <div class="d-flex">
                        <div class="position-relative w-90">
                            <el-input v-model="inputChat" placeholder="Mensaje" class="chat-input-inner" @keyup.enter.native="enviarMensajeGrupo" />
                        </div>
                        <el-upload
                        ref="upload"
                        class="upload-demo"
                        action="#"
                        :auto-upload="false"
                        :show-file-list="false"
                        :on-change="subirImagen"
                        :limit="1"
                        accept="image/*"
                        >
                            <i class="icon-add-img my-auto ml-2 f-20 text-86 cr-pointer" />
                        </el-upload>
                        <i class="icon-navigation my-auto ml-2 f-25 text-general cr-pointer" @click="enviarMensajeGrupo" />
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <!-- <modal-ver-img ref="abrirModalVerImg" /> -->
    </div>
</template>
<script>
import Chats from '~/services/chats/chat-admin'
import { mapMutations, mapGetters } from 'vuex'
export default {
    components:{
        // modalVerImg: () => import('../partials/modalVerImg'),
    },
    data(){
        return{
            inputChat: '',
            loading: false,
        }
    },
    computed:{
        ...mapGetters({
            conversacion:'chatsGrupos/conversacionGrupo',
            grupo:'chatsGrupos/grupoActivo',
            masMensajes:'chatsGrupos/masMensajes'
        })
    },
    methods:{
        async getConversacion(){
            try {
                this.$store.dispatch('chatsGrupos/getConversacion', this.grupo)
            } catch (error){
                this.errorCatch(error)
            }

        },
        async enviarMensajeGrupo(){
            try {
                if (this.inputChat === ''){
                    this.notificacion('Advertencia','Por favor poner un mensaje','warning')
                    return
                }
                this.loading = true
                const form = {
                    mensaje:this.inputChat,
                    id_grupo:this.grupo.id_grupo
                }
                
                const {data} = await Chats.enviarMensajeGrupo(form)
                this.inputChat = ''
                this.loading = false
            } catch (error){
                this.errorCatch(error)
                this.loading = false
            }
        },
        async subirImagen(file, filelist){

            const tipo = this.fileType(file.name)
            if(tipo !== 'image/*'){
                this.$refs.upload.clearFiles()
                this.notificacion('Advertencia','Por favor seleccione solo imagenes','warning')
                return
            }

            const model = {
                mensaje:file.raw,
                id_grupo:this.grupo.id_grupo
            }
            const form = this.crearFormData(model)
                
            const {data} = await Chats.enviarMensajeGrupo(form)
            this.$refs.upload.clearFiles()
        },
        verImgUnica(){
            this.$refs.abrirModalVerImg.toggle()
        }
    }
}
</script>
<style lang="scss" scoped>
.middle-chat{
    $bg-chat: #fff;
    $bg-bubble-left: #f5f5f5;
    $bg-bubble-right: #C8F5E6;
    $font-size-bubble: 13px;
    $color-text-bubble: #000;
    height: calc(100vh - 55px);
    .chat-bubble{
        border: 1px solid #F8FCFF;
        font-size: $font-size-bubble;
        color: $color-text-bubble;
        &-left{
            background: $bg-bubble-left;
        }
        &-right{
            background: $bg-bubble-right;
        }
        &-arrow-left{
            @extend .arrows-chat;
            left: -10px;
            border-color: $bg-bubble-left #00000000  #00000000 transparent;
        }
        &-arrow-right{
            @extend .arrows-chat;
            right: -10px;
            border-color: $bg-bubble-right #00000000  #00000000 transparent;
        }
        .arrows-chat{
            border-style: solid;
            border-width: 10px;
            top: 0px;
            height: 0;
            position: absolute;
            width: 0;
        }
    }
    .chat-input{
        position: absolute;
        width: 100%;
        bottom: -56px;
        .activo{
            color:#109881;
        }
        .icon-navigation{
            transform: rotate( 90deg );
        }
    }
    .bg-f1{
        background: #F1F1F1;
    }

}
</style>